import React, { useContext, useState } from 'react';
import ReactDOM from "react-dom/client";
import { useFormik } from 'formik';
import * as yup from 'yup';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import Avatar from '@mui/material/Avatar';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';

//Implementacion
import { ContextTemplateProvider, ContextTemplate } from "./components/react-components/ContextTemplate";

//  Assets
import ImageLanding from './assets/images/RenderLanding.jpg'


const Login = () => {

  const validationSchema = yup.object({
    username: yup
      .string('Ingrese el correo electrónico')
      // .email('Enter a valid email')
      .required('El nombre de usuario es requerido'),
    password: yup
      .string('Ingrese la contraseña')
      .min(3, 'La contraseña debe tener al menos 3 caracteres')
      .required('La contraseña es requerida'),
  
    promotionalcode:yup
      .string('Si tienes un código promocional, ingrésalo en esta casilla.')
  });

  const { openNotification } = useContext(ContextTemplate);

  //  Estados 

  const [loadingButton, setLoadingButton] = useState(''); // invited - user

  //  Función para manejar el inicio de sesión con usuario y contraseña
  const handleSubmit = async () => {
    // event.preventDefault();
    //  Se activa la carga de los botones y se inhabilitan para evitar acciones
    setLoadingButton('user');

    let formData = new FormData();
    formData.append('username', username.value);
    formData.append('password', password.value);
    formData.append('promotionalcode', promotionalcode.value);

    await fetch(
      process.env.ENDPOINT_LOGIN_HOYOS,
      {
        method: "POST",
        body: formData,
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (!data.error) {
          localStorage.setItem(process.env.LOCAL_STORAGE_TOKEN_HOYOS, data.data.token);
          localStorage.setItem(process.env.LOCAL_STORAGE_USER_NAME_HOYOS, data.data.fullname);
          localStorage.setItem(process.env.LOCAL_STORAGE_USER_TYPE_HOYOS, "1");
          localStorage.setItem(process.env.LOCAL_STORAGE_DELIVERY_TYPE_HOYOS, "2");
          window.location.href = "/personalizacion"
        } else {
          openNotification(`${data.message}`)
        }
      })
      .catch((error) => {
        openNotification("Error al realizar la petición: " + error);
      });

      //  Devuelvo los botones a su estado normal para volver a intentar
      setLoadingButton('');
  };


  //  Función para controlar el inicio de sesión como invitado
  const handleSubmitInv = async (event) => {
    event.preventDefault();
    setLoadingButton('invited');

    await fetch(
      process.env.ENDPOINT_LOGIN_INVITED_HOYOS,
      {
        method: "POST",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (!data.error) {
          localStorage.setItem(process.env.LOCAL_STORAGE_TOKEN_HOYOS, data.token);
          localStorage.setItem(process.env.LOCAL_STORAGE_DELIVERY_TYPE_HOYOS, "2");
          localStorage.setItem(process.env.LOCAL_STORAGE_USER_TYPE_HOYOS, "2");
          localStorage.removeItem(process.env.LOCAL_STORAGE_USER_NAME_HOYOS);

          window.location.href = "/personalizacion";
        } else {
          openNotification(`${data.message}`);
        }
      })
      .catch((error) => {
        openNotification("Error al realizar la petición" + error);
      });

    //  Devuelvo los botones a su estado normal para volver a intentar
    setLoadingButton('');

  };

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
      promotionalcode: ''
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${ImageLanding})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent:'center'
          }}>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent:'center'
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="button">
              Incio de sesión
            </Typography>
            <form onSubmit={formik.handleSubmit}>
              <TextField sx={{ mt: 3, mb: 2 }}
                fullWidth
                id="username"
                name="username"
                label="username"
                value={formik.values.username}
                onChange={formik.handleChange}
                error={formik.touched.username && Boolean(formik.errors.username)}
                helperText={formik.touched.username && formik.errors.username}
              />
              <TextField sx={{ mt: 3, mb: 2 }}
                fullWidth
                id="password"
                name="password"
                label="Contraseña"
                type="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
              />

              <TextField sx={{ mt: 3, mb: 2 }}
                fullWidth
                id="promotionalcode"
                name="promotionalcode"
                label="Código promocional"
                value={formik.values.promotionalcode}
                onChange={formik.handleChange}
                variant='filled'
                // error={formik.touched.promotionalcode && Boolean(formik.errors.promotionalcode)}
                helperText={'Si tienes un código promocional, ingrésalo en esta casilla.'}
              />
              <LoadingButton 
                type="submit"
                disabled={loadingButton != ''} 
                fullWidth 
                variant="contained" 
                sx={{ mt: 3, mb: 2 }}
                loading={loadingButton === 'user'}
                // loadingPosition="end"
                // startIcon={ loadingButton === 'user' && <SaveIcon />}
              >
                Iniciar Sesión
              </LoadingButton>

              <LoadingButton 
                onClick={handleSubmitInv} 
                disabled={loadingButton != ''} 
                fullWidth 
                variant="outlined" 
                sx={{ mt: 1, mb: 2 }}
                loading={loadingButton === 'invited'}
                // loadingPosition="end"
                // startIcon={loadingButton === 'user' && <SaveIcon />}
                // loadingIndicator="Ingresando como invitado..."
              >
                Ingresar como invitado
              </LoadingButton>

            </form>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <ContextTemplateProvider>
          <Login />
        </ContextTemplateProvider>
    </React.StrictMode>
);