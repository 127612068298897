/**
 * Plantilla de React para inicializar las vistas principales (Login, Personalización, Experiencia...)
 * Recibe como prop el hijo del componente que será envuelto en los providers de contexto y temas
 */

import React, { createContext, useContext, useEffect, useState } from 'react';



// Temas y colores de la app
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import {theme , defaultTheme} from '../../theme/index';

// Componentes React
import Notifications from './Notifications/Notifications';


export const ContextTemplate = createContext({});

export function ContextTemplateProvider({ children }) {
  //  Estados 
  const [openNotifications, setOpenNotifications] = useState(false);
  const [messageNotifications, setMessageNotifications] = useState('');
  const [notificationActions, setNotificationActions] = useState(null);
  const [notificationSeverity, setNotificationSeverity] = useState(null);

  //  Función para abrir la notificación con un mensaje específico
  const openNotification = (message, severity='error', actions = null, ) => {
    setOpenNotifications(true);
    setMessageNotifications(message);
    setNotificationSeverity(severity);
    setNotificationActions(actions);
  }

  const NotificationEvent = (e) =>{
    openNotification(e.detail.message, e.detail.severity, e.detail.actions);
  }

  useEffect(()=>{
    document.addEventListener('open_react_notification', NotificationEvent, false);

    //  Remover el eventlistener al desmontarse el componente
    return () => {
      document.removeEventListener('open_react_notification', NotificationEvent);
    }
  });
  
  return (
    // Envuelve el componente 'children' dentro del proveedor de tema y el contexto de la página
    <ContextTemplate.Provider value={{ openNotification }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        { children }

        <Notifications 
          open={openNotifications} 
          actions = {notificationActions}
          message={messageNotifications} 
          severity={notificationSeverity}
          autoHideDuration={6000}
          setOpen={setOpenNotifications}/>
      </ThemeProvider>
    </ContextTemplate.Provider>

  );
}

